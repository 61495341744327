<template>
  <div class="d-flex flex-column flex-fill">
    <hsLoading v-if="isLoading" class="flex-fill" />
    <section v-else class="position-relative py-1 px-3 py-lg-4 px-lg-5 mt-5 d-flex flex-column">
      <section class="d-flex w-100 details-members-container">
        <div>
          <div class="d-flex w-75">
            <b-img-lazy id="logo-sparkmembers" fluid :src="require('@/assets/images/logos/sparkmembers.svg')" />
            <hs-badge class="ml-2" variant="outline-secondary">BETA</hs-badge>
          </div>
          <p id="title-detail" class="mt-5 mb-2 font-weight-bold text-uppercase font-size-xs text-secondary">
            {{ $t('landing-page.views.sparkmembers-landing-page.index.title-detail') }}
          </p>
          <h4 id="title" class="title-text font-weight-bold pb-3">
            {{ $t('landing-page.views.sparkmembers-landing-page.index.title') }}
          </h4>
          <p id="sub-title">
            {{ $t('landing-page.views.sparkmembers-landing-page.index.sub-title') }}
          </p>

          <div class="d-flex justify-content-center justify-content-lg-start">
            <hs-button
              variant="cherry"
              class="btn-ensure-access mt-4"
              size="lg"
              @click="validatesIfItIsToShowTheAlertModalAndEnabled"
            >
              <b>{{ $t('landing-page.views.sparkmembers-landing-page.index.buttons.btn-ensure-access') }}</b>
            </hs-button>
          </div>
        </div>
        <div class="d-none d-lg-block">
          <b-img-lazy
            id="info-products-image"
            class="position-absolute"
            :src="require('@/assets/images/SparkMembers/LandingPage/infoproducts.svg')"
          />
        </div>
      </section>
      <section class="my-4 w-100">
        <b-row class="mx-0">
          <b-col sm="12" md="6" xl="4" class="mt-5 p-0">
            <InformationCard
              :title="$t('landing-page.views.sparkmembers-landing-page.index.information-card.card1.title')"
              :subtitle="$t('landing-page.views.sparkmembers-landing-page.index.information-card.card1.sub-title')"
              icon="box-usd"
            />
          </b-col>
          <b-col sm="12" md="6" xl="4" class="mt-5 p-0">
            <InformationCard
              :title="$t('landing-page.views.sparkmembers-landing-page.index.information-card.card2.title')"
              :subtitle="$t('landing-page.views.sparkmembers-landing-page.index.information-card.card2.sub-title')"
              icon="box-full"
              background="#ffd6de"
            />
          </b-col>
          <b-col sm="12" md="6" xl="4" class="mt-5 p-0">
            <InformationCard
              :title="$t('landing-page.views.sparkmembers-landing-page.index.information-card.card3.title')"
              :subtitle="$t('landing-page.views.sparkmembers-landing-page.index.information-card.card3.sub-title')"
              icon="lock"
              background="#afffd0"
            />
          </b-col>
        </b-row>

        <AlertModal :modal-id="modalId" @accepted="enableSparkMembersFeature" />
      </section>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import toastHelper from '@/shared/helpers/toast';
import { analyticsService, customerService, productService } from '@/services';

export default {
  name: 'SparkMembersLandingPage',
  data() {
    return {
      isLoading: false,
    };
  },
  components: {
    InformationCard: () => import('./components/InformationCard'),
    AlertModal: () => import('./components/AlertModal'),
    hsLoading: () => import('@/components/Loading'),
  },
  computed: {
    ...mapState('school', {
      school: state => state.selectedSchool,
    }),
    modalId() {
      return 'alert-modal';
    },
  },
  methods: {
    ...mapActions('school', ['enableFeature']),
    async validateIfHaveProducts() {
      const { school_products } = await productService.getAll([{ key: 'page', value: 1 }]);

      return Boolean(school_products.length);
    },
    async validatesIfItIsToShowTheAlertModalAndEnabled() {
      if (await this.validateIfHaveProducts()) {
        this.$bvModal.show(this.modalId);
        return;
      }

      this.enableSparkMembersFeature();
    },
    async enableSparkMembersFeature() {
      try {
        this.isLoading = true;

        await this.enableFeature({
          featureId: parseInt(process.env.VUE_APP_SPARKMEMBERS_FEATURE_ID),
          featureKey: 'spark_members',
        });

        analyticsService.track({
          event: 'SparkMembers activated',
        });

        this.$router.push({ name: 'ProductsList' });
      } catch (e) {
        toastHelper.dangerMessage(
          this.$t('landing-page.views.sparkmembers-landing-page.index.toast-messages.feature-activation-error')
        );
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-ensure-access {
  width: 277px;
  height: 57px;
  font-size: 20px;
}

#info-products-image {
  width: 400px;
  height: 532.47px;
  right: 50px;
  top: -135px;
}

@media only screen and (min-width: $screen-md) {
  .title-text {
    font-size: 30px;
  }

  .details-members-container {
    width: 40% !important;
  }
}
</style>
